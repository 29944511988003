import React from 'react'
import Seo from "~components/Seo"
import CartSection from '~components/sections/CartSection'
import CartItem from '~components/blocks/CartItem'
import { useWishlist } from '~context/siteContext'

const Wishlist = () => {
  const { wishlist, wishlistCount } = useWishlist()
  return (
    <>
      <Seo
        title="Your Wishlist"
				url="/wishlist"
      />
      <CartSection isWishlist>
        {wishlist.map(wishlistItem => (
          <CartItem data={wishlistItem} isWishlist />
        ))}
      </CartSection>
    </>
  )
}

export default Wishlist
